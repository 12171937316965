import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { MembersService } from '../apis/members.service';
import { NavigationService } from '../utils/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class LandingPageGuard implements CanActivate {
  constructor(private membersService: MembersService, private navigationService: NavigationService) {}
  async canActivate(_: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const member = await this.membersService.getCurrentMember();
    if (member && _.routeConfig?.path === '') {
      await this.navigationService.goToHome();
    }
    return true;
  }
}
