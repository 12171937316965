import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../utils/auth.service';
import { inject } from '@angular/core';
import { ShortLivedTokenService } from '../apis/short-lived-token.service';
import { ToastService } from '../utils/toast.service';
import { FirebaseError } from '@angular/fire/app';
import { Subscription } from 'rxjs';
import { MembersService } from '../apis/members.service';

export const shortLivedTokenGuard: CanActivateFn = async (route, state) => {
  const shortLivedToken = route.queryParamMap.get('slt');

  if (shortLivedToken) {
    const isOnboardingRoundRoute = route.routeConfig?.path === 'onboarding';

    const shortLivedTokenService = inject(ShortLivedTokenService);
    const toastService = inject(ToastService);
    const router = inject(Router);
    const authService = inject(AuthService);
    const membersService = inject(MembersService);
    let memberSubscription: Subscription;

    if (await membersService.getCurrentMember()) return true;

    memberSubscription = membersService.subscribe(member => {
      if (member) {
        if (!isOnboardingRoundRoute) router.navigate(['/home']);
        memberSubscription.unsubscribe();
      }
    });

    try {
      const customToken = await shortLivedTokenService.get(shortLivedToken);
      await authService.customTokenAuth(customToken);

      await toastService.addToast(
        {
          type: 'success',
          icon: 'check_checked',
          message: 'Login successful, redirecting..',
        },
        4000
      );
    } catch (error) {
      let errorMessage;

      if (error instanceof FirebaseError) {
        errorMessage = 'An error occurred during your sign-in process. Please try again.';
      } else if ((error as any)?.error?.message) {
        errorMessage = (error as any)?.error?.message;
      } else {
        errorMessage = 'Something went wrong.';
      }

      await toastService.addToast(
        {
          message: errorMessage,
          type: 'error',
        },
        4000
      );

      memberSubscription.unsubscribe();
      await router.navigate(['/']);
    }
  }
  return true;
};
