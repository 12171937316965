import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { defineCustomElements } from '@mightyhealth/web-components/loader';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { version } from './environments/build';
import { Environments } from './environments/environments';

const environment = Environments.getConfig();

Sentry.init({
  dsn: environment.SENTRY_DSN,
  environment: environment.environmentName,
  release: version,
  enabled: Environments.isProd(),

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: Environments.isProd() ? 0.5 : 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: Environments.isProd() ? 0.1 : 1.0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: Environments.isProd() ? 0.1 : 1.0,

  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: [
    'localhost',
    environment.API_UNAUTH_URL,
    environment.API_URL,
    environment.BASE_URL,
    'http://localhost:4200/',
  ],
  ignoreErrors: [
    'The play() request was interrupted by',
    'play() failed because the user',
    "The fetching process for the media resource was aborted by the user agent at the user's request",
    'not allowed by the user agent or the platform in the current context, possibly because the user denied permission',
    'NotAllowedError',
    'AbortError: AbortError',
    'Pending operation cancelled by exitFullscreen() call.', // error related to external library, such as @vimeo/player
    'y@webkit-masked-url(://hidden/)', // errors come from a safari extension
    'UnknownError: Database deleted by request of the user', // IndexedDB related error. Occurs only - and rarely - on Safari for iOS
    'Firebase: Error (auth/network-request-failed).', // Can occur for instable user's network connection. Thrown while attempting to signup or login

    // These two happen when a promise is rejected or an error is thrown with a non plain and non Error object
    'Non-Error exception captured with keys',
    'Non-Error promise rejection captured with keys',

    // These happen after deployments and can be fixed with a window.reload()
    // but Sentry is not capturing the exception on beforeSend
    'ChunkLoadError: Loading chunk',
    'isProxied',

    // Failed to fetch is a "normal" error that can occur in many situations
    // and shoul dnot impact users, but CORS errors are the same so this could
    // mask real errors
    'Error: Uncaught (in promise): TypeError: Failed to fetch',
    'Uncaught (in promise): TypeError: Load failed',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
  ],
  denyUrls: [/incognitobrowser/i, /localhost/i, /extensions\//i, /^chrome:\/\//i],
});

if (environment.production) {
  enableProdMode();
}

function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
(globalThis as any)['mighty-components-assets'] = '/web-components-assets';

defineCustomElements();
