import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { MembersService } from '../apis/members.service';
import {
  OnboardingExperimentService,
  WEB_ONBOARDING_UHC_SAML_VARIANT,
} from '../services/onboarding-experiment.service';
import { OnboardingControllerService } from '../utils/onboarding/onboarding-controller.service';
import { OnboardingStepsService } from '../utils/onboarding/onboarding-steps-builder.service';
import { ExperimentService } from '../utils/experiment.service';
import { OnboardingService } from '../utils/onboarding.service';
import { ONBOARDING_STEPS_DEFINITION_UHC_SAML } from '../pages/onboarding-v2/onboarding-steps-definition';
import { AuthService } from '../utils/auth.service';
import { ShortLivedTokenService } from '../apis/short-lived-token.service';
import { ToastService } from '../utils/toast.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard {
  protected onboardingService = inject(OnboardingService);

  constructor(
    private memberService: MembersService,
    private onboardingControllerService: OnboardingControllerService,
    private onboardingStepsService: OnboardingStepsService,
    private onboardingExperimentService: OnboardingExperimentService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    if (this.onboardingControllerService.initialized) {
      return true;
    }

    const {
      variant: variantQueryParams,
      firstName: firstName,
      lastName: lastName,
      email: email,
      dob: dateOfBirth,
      memberID: memberIdz,
      slt: shortLivedToken,
    } = route.queryParams;

    if (variantQueryParams === WEB_ONBOARDING_UHC_SAML_VARIANT.name) {
      this.onboardingService.setOnboarding(this.onboardingService.personalInfos, {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        email: email,
      });

      this.onboardingService.setOnboarding(this.onboardingService.shortLivedToken, shortLivedToken);
    }

    let forceVariants = undefined;

    if (variantQueryParams !== undefined)
      forceVariants = Array.isArray(variantQueryParams) ? variantQueryParams : [variantQueryParams];

    const variant = await this.onboardingExperimentService.startOnboardingExperiment(forceVariants);
    let routes = variant.route;

    const startFrom = route.firstChild?.children[0].routeConfig?.path;
    const member = await this.memberService.getCurrentMember();
    if (member) {
      routes = routes.filter(route => !['date-of-birth', 'signup'].includes(route));
    }

    const steps = this.onboardingStepsService.getSteps(routes);

    await this.onboardingControllerService.init(steps, startFrom);

    return true;
  }
}

export const prepareOnboardingGuard = (route: ActivatedRouteSnapshot) => inject(OnboardingGuard).canActivate(route);
